@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    @apply font-roboto
}
.open_header_link{
    @apply text-[#000000] text-[14px] uppercase font-bold transition-all duration-300 cursor-pointer hover:text-mainRed
}
.close_header_link{
    @apply text-[#ffffff] w-full text-[14px] uppercase font-bold transition-all duration-300 cursor-pointer  
}
.Open{
    @apply fixed w-80 h-[100vh] bg-[#152136] top-0 right-0 translate-x-0 transition-all duration-500 overflow-y-auto z-50
}
.Close{
    @apply fixed w-80 h-[100vh] bg-[#152136] top-0 right-0 translate-x-[100%] transition-all duration-500 z-50
}
.text{
    animation-name:aaa;
    animation-duration:1s;
    animation-fill-mode:forward;
}
@keyframes aaa {
 from {
  opacity: 0;
  transform: translateY(-100px);
 }
 to {
  opacity: 1;
  transform: translate(0px);
 }
}
.react-tabs__tab-list{
    border-bottom: none !important;
    display: flex;
    justify-content: space-evenly;
}

.react-tabs__tab{
    padding-left: 5vw !important;
    padding-right: 5vw !important;
    padding-top: 1.2vw !important;
    padding-bottom: 1.2vw !important;
    font-family: 'Prata', serif;
    font-size: 16px;
    font-weight: 600 !important;
    color: black !important;
    background-color: white !important;
    border-radius: 10px !important;
}
.react-tabs__tab--selected{
    border-radius: 10px !important;
    border: none !important;
    background: #9e4b47 !important;
    color: white !important;
    transition: all .5s linear;
    box-shadow: none !important;
}

@media screen and (max-width:992px){
 .react-tabs__tab-list{
   /* flex-wrap:wrap;
    padding-left:20px;
    padding-right:20px;*/
 }
 .react-tabs__tab{
    padding-left:25px !important;
    padding-right:25px !important;
    font-size:12px;
 }   
}
@media screen and (max-width:640px){
 .react-tabs__tab-list{
   flex-direction:column;
 }
 .react-tabs__tab{
    width:70%;
    margin:auto;
    margin-top:10px;
    font-size:16px;
 }   
}
.Par_BG{
    padding: 90px 0px 90px 0px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.ShaD{
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.04);

}
.Opts{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.St_Header{
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}